<template>
  <div>
    <div>
      <TheTitleBar
        sectionTitle="dashboard.label.mnuListings"
        sectionID="sellerListings"
        :showCount="true"
        :showValue="true"
        :showFilters="true"
        :showSort="true"
        :showSellButton="false"
        :totalCount="totalVehicles"
        :totalValue="currencyFormatter(totalAmount)"
        @qSortValue="sort"
        @qDateFilter="filterList"
      />
    </div>
    <!-- <p>dateFilter: {{dateFilter}}</p>
<p>dateFilter: {{statusFilter}}</p> -->
    <b-container v-if="cardsData.length === 0">
      <h1 class="p-5 my-5 text-secondary text-center">
        {{ $t("dashboard.label.noResults") }}
      </h1>
    </b-container>
    <div v-else>
      <TheSellerDetailCard
        @qDateFilter="filterList"
        detailType="listings"
        :carDetail="carDetail"
        v-for="(carDetail, key) in cardsData"
        :key="key"
      />
    </div>

    <!-- <p v-for="data, key in $store.getters.myListingsList.CardsData" :key="key">list[{{key}}]: {{ data }}</p> -->
  </div>
</template>

<script>
import TheTitleBar from "@/components/dashboard/global/TheTitleBar.vue";
import TheSellerDetailCard from "@/components/dashboard/seller/TheSellerDetailCard.vue";

export default {
  components: {
    TheTitleBar,
    TheSellerDetailCard,
  },
  data() {
    return {
      sortBy: "Defaults",
      filterByDate: "Defaults",
      filterByStatus: "Defaults",
      dateFilter: null,
      statusFilter: null,
      sortOrder: 0,
      totalAmount: 0,
      totalVehicles: 0,
    };
  },
  computed: {
    cardsData() {
      let l = [];
      if (this.$store.getters.myListingsList)
        l = this.$store.getters.myListingsList.CardsData;
      return l;
    },
  },
  methods: {
    sort(sortOrder) {
      const l = this.cardsData;
      if (sortOrder === 6) l.sort((a, b) => (a.DateListed < b.DateListed ? 1 : -1));
      if (sortOrder === 5) l.sort((a, b) => (a.DateListed > b.DateListed ? 1 : -1));
      if (sortOrder === 4)
        l.sort((a, b) => (a.KilometersMiles.Kilometers < b.Kilometers ? 1 : -1));
      if (sortOrder === 3)
        l.sort((a, b) => (a.KilometersMiles.Kilometers > b.Kilometers ? 1 : -1));
      if (sortOrder === 2) l.sort((a, b) => (a.Price.Amount < b.Price.Amount ? 1 : -1));
      if (sortOrder === 1) l.sort((a, b) => (a.Price.Amount > b.Price.Amount ? 1 : -1));
    },
    currencyFormatter(value) {
      const sc = this.$store.getters.currencyForSeller;
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: sc ? sc : "CAD",
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    filterList(dateFilter, statusFilter) {
      this.dateFilter = dateFilter;
      this.statusFilter = statusFilter;
      let parameter = {
        status: statusFilter ? statusFilter : [-1],
        date: dateFilter ? dateFilter : -1,
      };
      this.$store.dispatch("getMyListingsList", parameter);
    },
    getTotal() {
      if (this.cardsData) {
        this.totalVehicles = this.cardsData.length;
        this.totalAmount = this.cardsData
          .map((item) => (item.Price.Amount ? parseInt(item.Price.Amount) : 0))
          .reduce((prev, curr) => prev + curr, 0);
      }
    },
  },
  updated() {
    this.getTotal();
  },
  beforeMount() {
    this.filterList();
    this.getTotal();
    this.$store.dispatch("setCurrency", "CAD");
    this.$store.dispatch('setDashboardType', 'SellerDashboard')
  },
};
</script>
